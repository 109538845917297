/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { MapCore } from './components/MapCore';
import brushesData from './brushes.json';
import 'ol/ol.css';
import { EventsKey } from 'ol/events';
import { Feature } from 'ol';
import { Point } from 'ol/geom';
import { Polygon } from 'ol/geom';
import { MapBrowserEvent } from 'ol';
import { Map } from 'ol';
import WebGLTileLayer from 'ol/layer/WebGLTile';
import GeoTIFF from 'ol/source/GeoTIFF';
import { calculateModificationImpact, getCategoryFile, polygonToClipCoordinates } from './utilities';
import { CATEGORIES, SELECTED_BLUE, SELECTED_BLUE_BG, BORDER_COLOR } from './constants';
import { Style, Fill, Stroke, Circle as StyleCircle } from 'ol/style';
import { getAssetUrl } from './utilities';
import { useDrawingSystem } from './components/DrawingSystem';
import { useAreaSelectionSystem } from './components/AreaSelectionSystem';
import { useSearchSystem } from './components/SearchSystem';
import { useCanvasSystem } from './components/CanvasSystem';
import { fromUrl } from 'geotiff';
import { Geometry } from 'ol/geom';
import RenderEvent from 'ol/render/Event';
import { FeatureLike } from 'ol/Feature';
import { Draw } from 'ol/interaction';
import { DrawEvent } from 'ol/interaction/Draw';
import { ReportGenerator } from './services/ReportGenerator';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { PDFReportLayout } from './components/PDFReportLayout';
import { BlobProvider } from '@react-pdf/renderer';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { PaymentModal } from './components/PaymentModal';
import { NominatimResult } from './components/SearchSystem';

// Import types
import {
  Brush,
  BrushesData,
  UserModifications,
} from './types';

import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Layer } from 'ol/layer';
import TileLayer from 'ol/layer/Tile';
import VectorTileLayer from 'ol/layer/VectorTile';

// Add this interface definition
interface ReportData {
  baseMapImage: string;
  bandImages: BandImage[];
  bandDescriptions: string[];
  interventions: Array<{
    name: string;
    area: number;
    cost: number;
    color: string;
    values: number[];
  }>;
  bandTotals: number[];
  totalCost: number;
  selectedArea: number;
  dateGenerated: Date;
  projectName: string;
  location: string;
}

// Add interface for band images
interface BandImage {
  base: string;
  withInterventions: string;
}

function App() {
  const [currentBrush, setCurrentBrush] = useState<Brush | null>(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [brushes] = useState<BrushesData>(brushesData);

  const [selectedCategory, setSelectedCategory] = useState('green_infrastructure');
  const [selectedBands, setSelectedBands] = useState<number[]>([]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [bandDescriptions, setBandDescriptions] = useState<string[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pixelValues, setPixelValues] = useState<number[]>([]);

  const [brushSize, setBrushSize] = useState(5); // 5m² initial area
  const [interventionLayer, setInterventionLayer] = useState<WebGLTileLayer | null>(null);
  // const [brushUsage, setBrushUsage] = useState<Record<string, number>>({});
  const [totalCost, setTotalCost] = useState<number>(0);
  const [bandTotals, setBandTotals] = useState<number[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [rasterInfo, setRasterInfo] = useState<any>(null);
  const [modificationLayer, setModificationLayer] = useState<VectorLayer<VectorSource> | null>(null);

  const [userModifications, setUserModifications] = useState<UserModifications>({});
  const [bandSums, setBandSums] = useState<number[]>(new Array(bandDescriptions.length).fill(0));
  // const [currentCategory, setCurrentCategory] = useState<string>('energy');

  const [selectedArea, setSelectedArea] = useState<Polygon | null>(null);
  const [areaPixelSums, setAreaPixelSums] = useState<number[]>([]);
  const [totalPixels, setTotalPixels] = useState<number>(0);
  const [activeSelection, setActiveSelection] = useState<boolean>(false);

  const mapInstanceRef = useRef<Map | null>(null);

  // Replace all canvas-related code with the hook
  const { 
    canvasLayers, 
    drawOnCanvas, 
    clearCanvas, 
    bandSums: canvasBandSums,
    brushAreas: canvasBrushAreas,
    calculateBandSums,
    getInterventionAtPixel
  } = useCanvasSystem({
    mapInstance: mapInstanceRef.current,
    selectedBands,
    bandDescriptions,
    currentBrush
  });

  const [maskLayer, setMaskLayer] = useState<WebGLTileLayer | null>(null);
  const [maskVectorLayer, setMaskVectorLayer] = useState<VectorLayer<VectorSource<Feature<Geometry>>> | null>(null);

  const selectBrush = useCallback((brush: Brush) => {
    if (currentBrush?.name === brush.name) {
      setCurrentBrush(null);
    } else {
      setCurrentBrush(brush);
    }
  }, [currentBrush]);

  const updateModificationLayer = useCallback(() => {
    if (!modificationLayer || !mapInstanceRef.current) return;

    const source = modificationLayer.getSource();
    if (!(source instanceof VectorSource)) return;

    const features: Feature[] = [];

    Object.entries(userModifications[selectedCategory] || {}).forEach(([pixelKey, modification]) => {
      const [lon, lat] = pixelKey.split(',').map(Number);
      const feature = new Feature({
        geometry: new Point([lon, lat]),
        brushName: modification.brushName,
        brushSize: modification.brushSize,
      });
      features.push(feature);
    });


    // Clear existing features and add new ones
    source.clear();
    source.addFeatures(features);

    modificationLayer.changed();
    mapInstanceRef.current.render();
  }, [modificationLayer, mapInstanceRef, selectedCategory, userModifications]);

  // Remove the draw, startDrawing, and stopDrawing functions
  // Replace them with the hook:
  const { draw, startDrawing, stopDrawing } = useDrawingSystem({
    mapInstance: mapInstanceRef.current,
    isDrawing,
    setIsDrawing,
    currentBrush,
    brushSize,
    selectedArea,
    activeSelection,
    selectedCategory,
    canvasLayers,
    setAreaPixelSums,
    setMaskVectorLayer
  });

  // Update the band change handler
  const handleBandChange = useCallback((bandNumber: number) => () => {
    if (!mapInstanceRef.current) return;
    
    const cogLayer = mapInstanceRef.current.getLayers().getArray().find(
      layer => layer instanceof WebGLTileLayer && layer.get('purpose') === 'cog'
    ) as WebGLTileLayer;
    
    if (!cogLayer) {
            return;
    }

    setSelectedBands(prev => {
      const newBands = prev.includes(bandNumber) 
        ? prev.filter(b => b !== bandNumber)
        : [...prev, bandNumber];
      
      // Update layer style
      cogLayer.setStyle({
        color: newBands.length > 0 ? [
          'array',
          newBands.length === 1
            ? ['band', newBands[0]]
            : ['/', ['+', ...newBands.map(band => ['band', band])], newBands.length],
          newBands.length === 1
            ? ['band', newBands[0]]
            : ['/', ['+', ...newBands.map(band => ['band', band])], newBands.length],
          newBands.length === 1
            ? ['band', newBands[0]]
            : ['/', ['+', ...newBands.map(band => ['band', band])], newBands.length],
          0.3
        ] : ['array', 0, 0, 0, 0]
      });
      
      return newBands;
    });
  }, [mapInstanceRef]);

  // Update the category change handler
  const handleCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (!mapInstanceRef.current) return;
    
    const newCategory = event.target.value;
    
    // Create a new layer with error handling
    const url = getAssetUrl('base', getCategoryFile(CATEGORIES, newCategory));
    
    const newCogLayer = new WebGLTileLayer({
      source: new GeoTIFF({
        sources: [{
          url: url,
        }],
        // Add error handling options
        transition: 0,
        interpolate: false,
        wrapX: false,
      }),
      style: {
        color: selectedBands.length > 0 ? [
          'array',
          // Sum all selected bands and normalize
          ['/', 
            ['+', ...selectedBands.map(band => ['band', band])],
            selectedBands.length
          ],
          ['/', 
            ['+', ...selectedBands.map(band => ['band', band])],
            selectedBands.length
          ],
          ['/', 
            ['+', ...selectedBands.map(band => ['band', band])],
            selectedBands.length
          ],
          0.3
        ] : ['array', 0, 0, 0, 0]
      },
      visible: selectedBands.length > 0,
      zIndex: 1
    });

    // Add error handling for the layer
    newCogLayer.on('error', (error) => {
            alert('Error loading map layer. Please try again later.');
    });

    try {
      mapInstanceRef.current.getLayers().setAt(1, newCogLayer);
    } catch (error) {
            alert('Error updating map. Please refresh the page.');
    }
    
    setSelectedCategory(newCategory);
    setCurrentBrush(null);

    // Remove existing mask layer if any
    if (maskLayer && mapInstanceRef.current) {
      mapInstanceRef.current.removeLayer(maskLayer);
      setMaskLayer(null);
    }
  };

  // Add effect to handle initial layer setup
  useEffect(() => {
    if (!mapInstanceRef.current) return;

    const cogLayer = mapInstanceRef.current.getLayers().getArray()[1] as WebGLTileLayer;
    if (!(cogLayer instanceof WebGLTileLayer)) return;

    // Set initial visibility
    cogLayer.setVisible(selectedBands.length > 0);

  }, [selectedBands]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const drawAtPixel = (x: number, y: number) => {
    // Implementation here
  };

  useEffect(() => {
    if (mapInstanceRef.current) {
      mapInstanceRef.current.updateSize();
    }
  }, []);

  // Add effect for loading intervention layer
  useEffect(() => {
    if (!mapInstanceRef.current) return;

    const interventionFile = `${selectedCategory}_base.tif`;
    const interventionSource = new GeoTIFF({
      sources: [
        {
          url: `${window.location.origin}/bucket/base/${interventionFile}`,
        },
      ],
    });

    const newInterventionLayer = new WebGLTileLayer({
      source: interventionSource,
      style: {
        color: ['color', 255, 0, 0, 0.5], // Semi-transparent red
      },
      visible: false,
    });

    setInterventionLayer(newInterventionLayer);
    mapInstanceRef.current.addLayer(newInterventionLayer);

    return () => {
      if (mapInstanceRef.current && interventionLayer) {
        mapInstanceRef.current.removeLayer(interventionLayer);
      }
    };
  }, [selectedCategory]);

  // Add effect for calculating total cost
  useEffect(() => {
    const newTotalCost = Object.values(userModifications).reduce((total, categoryModifications) => {
      return total + Object.values(categoryModifications).reduce((categoryTotal, modification) => {
        const brush = brushes[selectedCategory]?.find((b: Brush) => b.name === modification.brushName);
        return categoryTotal + (brush ? brush.cost : 0);
      }, 0);
    }, 0);
    setTotalCost(newTotalCost);
  }, [userModifications, brushes, selectedCategory]);

  // Add effect for calculating band totals
  useEffect(() => {
    if (!mapInstanceRef.current) return;

    const cogLayer = mapInstanceRef.current.getLayers().getArray()[0] as WebGLTileLayer;
    const extent = cogLayer.getExtent();
    
    if (!extent) return;

    const calculateTotals = async () => {
      const data = await cogLayer.getData(extent);
      if (!data) return;

      // Check if data is an object with a 'data' property
      const pixelData = 'data' in data ? data.data : data;

      if (!(pixelData instanceof Float32Array || pixelData instanceof Uint8Array || pixelData instanceof Uint8ClampedArray)) {
                return;
      }

      const width = 'width' in data && typeof data.width === 'number' ? data.width : Math.sqrt(pixelData.length);
      const height = 'height' in data && typeof data.height === 'number' ? data.height : Math.sqrt(pixelData.length);
      const bandCount = Math.round(pixelData.length / (width * height));

      const totals = new Array(bandCount).fill(0);

      for (let i = 0; i < pixelData.length; i += bandCount) {
        for (let j = 0; j < bandCount; j++) {
          totals[j] += pixelData[i + j];
        }
      }

      setBandTotals(totals);
    };

    calculateTotals();
  }, [selectedCategory]);

  // Add caching function
  const fetchWithCache = async (url: string) => {
    const cache = await caches.open('geotiff-cache');
    let response = await cache.match(url);
    if (!response) {
      response = await fetch(url);
      cache.put(url, response.clone());
    }
    return response;
  };

  // Update the fetchMetadata function
  const fetchMetadata = async () => {
    const selectedFile = CATEGORIES.find(cat => cat.value === selectedCategory)?.file;
    if (!selectedFile) return;

    try {
      const url = getAssetUrl('base', selectedFile);

      const tiff = await fromUrl(url);

      const image = await tiff.getImage();

      const metadata = await image.getFileDirectory();

      if (metadata && metadata.GDAL_METADATA) {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(metadata.GDAL_METADATA, "text/xml");

        const items = Array.from(xmlDoc.getElementsByTagName('Item'));

        const bandDescriptions = items
          .filter(item => item.getAttribute('name') === 'DESCRIPTION')
          .map(item => item.textContent || '');

        setBandDescriptions(bandDescriptions);
      } else {
        // Try alternative metadata sources
        const descriptions = [];
        for (let i = 1; i <= metadata.StripOffsets.length; i++) {
          const desc = metadata[`GDAL_DESCRIPTION_${i}`] || 
                      metadata[`DESCRIPTION_${i}`] ||
                      metadata[`Band_${i}`];
          descriptions.push(desc || `Band ${i}`);
        }
        if (descriptions.length > 0) {
          setBandDescriptions(descriptions);
        }
      }
    } catch (error) {
            setBandDescriptions(Array(10).fill('Band'));
    }
  };

  useEffect(() => {
    fetchMetadata();
  }, [selectedCategory, CATEGORIES]);

  useEffect(() => {
    if (!mapInstanceRef.current) return;

    const modificationSource = new VectorSource();
    const newModificationLayer = new VectorLayer({
      source: modificationSource,
      style: (feature) => {
        const brushName = feature.get('brushName');
        const brushSizeMapUnits = feature.get('brushSize') || 5;
        const color = currentBrush?.color || 'rgba(255, 0, 0, 0.3)';
        
        // Convert the map units to screen pixels for the style
        const resolution = mapInstanceRef.current?.getView().getResolution() || 1;
        const pixelRadius = brushSizeMapUnits / resolution;
        
        return new Style({
          image: new StyleCircle({
            radius: pixelRadius,
            fill: new Fill({ color: color }),
            stroke: new Stroke({ color: 'black', width: 1 })
          }),
        });
      },
      zIndex: 10,
    });

    mapInstanceRef.current.addLayer(newModificationLayer);
    setModificationLayer(newModificationLayer);

    return () => {
      if (mapInstanceRef.current) {
        mapInstanceRef.current.removeLayer(newModificationLayer);
      }
    };
  }, [mapInstanceRef]);

  useEffect(() => {
    if (mapInstanceRef.current && modificationLayer) {
      const layers = mapInstanceRef.current.getLayers();
      if (!layers.getArray().includes(modificationLayer)) {
        mapInstanceRef.current.addLayer(modificationLayer);
      }
      modificationLayer.setVisible(true);
      modificationLayer.setZIndex(Infinity); // Ensure it's on top
    }
  }, [modificationLayer]);

  useEffect(() => {
    const map = mapInstanceRef.current;
    if (!map) return;

    // Force map to update its size after initialization
    setTimeout(() => {
      map.updateSize();
    }, 100);
  }, []);

  useEffect(() => {
    if (mapInstanceRef.current) {
      const webGLLayers = mapInstanceRef.current.getLayers().getArray().filter(layer => layer instanceof WebGLTileLayer);
      const listeners: EventsKey[] = [];

      webGLLayers.forEach(layer => {
        const listener = layer.on('change', () => {
        });
        listeners.push(listener);
      });

      // Cleanup function to remove listeners when component unmounts
      return () => {
        listeners.forEach(listener => {
          if (listener && listener.target) {
            listener.target.removeEventListener(listener.type, listener.listener);
          }
        });
      };
    }
  }, []);

  useEffect(() => {
  }, [currentBrush]);

  useEffect(() => {
    if (!mapInstanceRef.current) return;

    const handlePointerMove = async (event: MapBrowserEvent<UIEvent>) => {
      const pixel = mapInstanceRef.current!.getEventPixel(event.originalEvent);
      const layers = mapInstanceRef.current!.getLayers().getArray();
      const cogLayer = layers.find(layer => layer instanceof WebGLTileLayer) as WebGLTileLayer;
      
      if (cogLayer && cogLayer instanceof WebGLTileLayer) {
        try {
          // Get the data at the current pixel d
          const data = await cogLayer.getData(pixel);
          if (data) {
            // If data is an array-like object, convert it to an array
            const pixelData = Array.isArray(data) ? data : Array.from(data as Float32Array);
            setPixelValues(pixelData);
          }
        } catch (error) {
                  }
      }
    };

    // Debounce the handler to improve performance
    const debouncedHandler = (event: MapBrowserEvent<UIEvent>) => {
      setTimeout(() => handlePointerMove(event), 50);
    };

    mapInstanceRef.current.on('pointermove', debouncedHandler);

    return () => {
      if (mapInstanceRef.current) {
        mapInstanceRef.current.un('pointermove', debouncedHandler);
      }
    };
  }, [mapInstanceRef.current]);

  // Replace the area selection functions with the hook
  const {
    isSelectionMode,
    toggleSelectionMode,
    clearSelection,
    calculateAreaStats
  } = useAreaSelectionSystem({
    mapInstance: mapInstanceRef.current,
    selectedCategory,
    bandDescriptions,
    userModifications,
    setAreaPixelSums,
    setTotalPixels,
    setActiveSelection,
    setSelectedArea,
    setMaskVectorLayer,
    setCurrentBrush,
    clearCanvas,
    calculateBandSums,
    setUserModifications,
    setBandSums: (newBandSums) => {
      // Update any UI components that depend on bandSums
      calculateBandSums();
    }
  });

  // Replace the searchHandlers useMemo with the new hook
  const {
    searchQuery,
    setSearchQuery,
    search
  } = useSearchSystem({
    mapInstance: mapInstanceRef.current,
    selectedBands,
    selectedCategory,
    CATEGORIES
  });

  // Add effect to handle canvas resize
  useEffect(() => {
    if (!mapInstanceRef.current || !canvasLayers) return;

    const updateCanvasSizes = () => {
      const size = mapInstanceRef.current!.getSize();
      if (!size) return;

      Object.values(canvasLayers).forEach(canvas => {
        canvas.width = size[0];
        canvas.height = size[1];
        
        const gl = (canvas as any).gl as WebGLRenderingContext;
        if (gl) {
          gl.viewport(0, 0, canvas.width, canvas.height);
        }
      });
    };

    // Initial update
    updateCanvasSizes();

    // Handle window resize
    window.addEventListener('resize', updateCanvasSizes);

    // Handle container resize
    const observer = new ResizeObserver(updateCanvasSizes);
    observer.observe(mapInstanceRef.current.getTargetElement());

    return () => {
      window.removeEventListener('resize', updateCanvasSizes);
      observer.disconnect();
    };
  }, [canvasLayers, mapInstanceRef]);

  // Add an effect to recalculate area stats when modifications change
  useEffect(() => {
    if (!selectedArea || !activeSelection) return;

    const recalculateAreaStats = async () => {
      try {
        // Get base stats from GeoTIFF
        await calculateAreaStats(selectedArea);

        // Add impact of all modifications
        const modifications = userModifications[selectedCategory] || {};
        const modificationImpact = new Array(bandDescriptions.length).fill(0);

        Object.entries(modifications).forEach(([coordString, modification]) => {
          const coordinate = coordString.split(',').map(Number);
          const impactValues = calculateModificationImpact(
            coordinate,
            modification.values,
            selectedArea
          );
          
          impactValues.forEach((value, index) => {
            modificationImpact[index] += value;
          });
        });

        // Update area sums with modification impact
        setAreaPixelSums(prev => 
          prev.map((value, index) => value + modificationImpact[index])
        );

      } catch (error) {
              }
    };

    recalculateAreaStats();
  }, [userModifications, selectedCategory, selectedArea, activeSelection]);

  // Add cleanup effect for mask layer
  useEffect(() => {
    return () => {
      if (mapInstanceRef.current) {
        if (maskLayer) {
          mapInstanceRef.current.removeLayer(maskLayer);
        }
        if (interventionLayer) {
          mapInstanceRef.current.removeLayer(interventionLayer);
        }
      }
    };
  }, [maskLayer, interventionLayer]);

  // Function to create a vector layer for the mask
  const createMaskVectorLayer = (polygon: Polygon): VectorLayer<VectorSource<Feature<Geometry>>> => {
    const extent = mapInstanceRef.current!.getView().calculateExtent();
    const extentRing = [
      [extent[0], extent[1]],
      [extent[2], extent[1]],
      [extent[2], extent[3]],
      [extent[0], extent[3]],
      [extent[0], extent[1]]
    ];

    const polygonWithHole = new Polygon([extentRing, polygon.getCoordinates()[0]]);

    const source = new VectorSource<Feature<Geometry>>({
      features: [new Feature({
        geometry: polygonWithHole
      })]
    });

    const layer = new VectorLayer<VectorSource<Feature<Geometry>>>({
      source: source,
      style: new Style({
        fill: new Fill({
          color: 'rgba(70, 70, 70, 0.5)'
        }),
        stroke: new Stroke({
          color: 'rgba(0, 0, 255, 1)',  // Blue border
          width: 2
        })
      }),
      zIndex: 3
    });

    return layer;
  };

  // Ensure this function is called when drawing ends
  const handleDrawEnd = useCallback((event: DrawEvent) => {
    const feature = event.feature;
    const geometry = feature.getGeometry();
    
    if (geometry instanceof Polygon) {
      setSelectedArea(geometry);
      
      if (mapInstanceRef.current) {
        if (maskVectorLayer) {
          mapInstanceRef.current.removeLayer(maskVectorLayer);
          setMaskVectorLayer(null);
        }

        const extent = mapInstanceRef.current.getView().calculateExtent();
        const extentRing = [
          [extent[0], extent[1]],
          [extent[2], extent[1]],
          [extent[2], extent[3]],
          [extent[0], extent[3]],
          [extent[0], extent[1]]
        ];

        const polygonWithHole = new Polygon([extentRing, geometry.getCoordinates()[0]]);
        const source = new VectorSource<Feature<Geometry>>({
          features: [new Feature({
            geometry: polygonWithHole
          })]
        });

        const newMaskVectorLayer = new VectorLayer<VectorSource<Feature<Geometry>>>({
          source: source,
          style: new Style({
            fill: new Fill({
              color: 'rgba(70, 70, 70, 0.5)'
            }),
            stroke: new Stroke({
              color: 'rgba(0, 0, 255, 1)',  // Blue border
              width: 2
            })
          }),
          zIndex: 100
        });

        mapInstanceRef.current.addLayer(newMaskVectorLayer);
        setMaskVectorLayer(newMaskVectorLayer);
      }

      setActiveSelection(true);
    }
  }, [mapInstanceRef, maskVectorLayer]);

  // Add this near the other useCallback/function definitions
  const handleMapReady = useCallback((map: Map) => {
    mapInstanceRef.current = map;
  }, []);

  // Add these new states near the other state declarations
  const [sortBand, setSortBand] = useState<number | 'cost' | null>(null);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');

  // Add this sorting function near other useCallback functions
  const getSortedBrushes = useCallback(() => {
    if (sortBand === null) return brushes[selectedCategory];
    
    return [...brushes[selectedCategory]].sort((a, b) => {
      // Skip eraser from sorting
      if (a.name === 'Eraser') return 1;
      if (b.name === 'Eraser') return -1;
      
      if (sortBand === 'cost') {
        // Sort by cost
        return sortDirection === 'desc' ? b.cost - a.cost : a.cost - b.cost;
      } else {
        // Sort by band value
        const aValue = a.values?.[sortBand] ?? 0;
        const bValue = b.values?.[sortBand] ?? 0;
        return sortDirection === 'desc' ? bValue - aValue : aValue - bValue;
      }
    });
  }, [selectedCategory, sortBand, sortDirection, brushes]);

  // Add this state for intervention hover info
  const [hoverIntervention, setHoverIntervention] = useState<{
    name: string;
    values: number[];
  }>({ name: '', values: new Array(bandDescriptions.length).fill(0) });

  // Add new state for loading
  const [isGeneratingReport, setIsGeneratingReport] = useState(false);

  // Add these helper methods inside App component
  const captureBaseMap = async (): Promise<string> => {
    try {
      const mapCanvas = mapInstanceRef.current?.getTargetElement().querySelector('canvas');
      if (!mapCanvas) return '';

      const exportCanvas = document.createElement('canvas');
      const context = exportCanvas.getContext('2d');
      if (!context) return '';

      exportCanvas.width = mapCanvas.width;
      exportCanvas.height = mapCanvas.height;

      context.drawImage(mapCanvas, 0, 0);

      return exportCanvas.toDataURL('image/png');
    } catch (error) {
            return '';
    }
  };

  const handleGenerateAndDownloadReport = useCallback(async () => {
    if (!mapInstanceRef.current) return;
    
    // Show payment modal first
    setShowPaymentModal(true);
    setReportPending(true);
  }, [mapInstanceRef]);

  // Add new handler for successful payment
  const handlePaymentSuccess = useCallback(async () => {
    setShowPaymentModal(false);
    setIsGeneratingReport(true);
    
    try {
      const reportGenerator = new ReportGenerator(
        mapInstanceRef.current!,
        bandDescriptions,
        getSortedBrushes(),
        canvasBrushAreas,
        canvasLayers,
        {
          bandTotals: areaPixelSums,
          totalCost,
          selectedArea: totalPixels,
          dateGenerated: new Date(),
          projectName: "Urban Development Analysis",
          location: searchQuery || "Custom Area"
        }
      );

      await reportGenerator.generateReport();
    } catch (error) {
          } finally {
      setIsGeneratingReport(false);
      setReportPending(false);
    }
  }, [
    mapInstanceRef,
    bandDescriptions,
    getSortedBrushes,
    canvasBrushAreas,
    canvasLayers,
    areaPixelSums,
    totalCost,
    totalPixels,
    searchQuery
  ]);

  const handleClearSelection = useCallback(() => {
    setSelectedArea(null);
    setActiveSelection(false);
    clearCanvas();
    setTotalCost(0);
    
    const vectorLayer = mapInstanceRef.current?.getLayers().getArray()
      .find(layer => layer instanceof VectorLayer) as VectorLayer<VectorSource<Feature<Geometry>>>;
    
    if (vectorLayer) {
      vectorLayer.getSource()?.clear();
    }
  }, [clearCanvas, setSelectedArea, setActiveSelection, setTotalCost]);

  // Add this near the top of the App component with other state declarations
  const [reportData, setReportData] = useState<ReportData | null>(null);

  // Add near other state declarations
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [reportPending, setReportPending] = useState(false);

  return (
    <div className="app">
      {/* Top controls - just the category selector */}
      <div style={{ padding: '10px', display: 'flex', alignItems: 'center' }}>
        <select className="category-select" value={selectedCategory} onChange={handleCategoryChange}>
          {CATEGORIES.map((category) => (
            <option key={category.value} value={category.value}>{category.label}</option>
          ))}
        </select>
      </div>

      {/* Main content area */}
      <div className="main-content">
        {/* Left panel */}
        <div className="left-panel">
          {/* Band buttons */}
          {bandDescriptions.map((description, index) => {
            // Calculate maxValue for the progress bars
            const maxValue = Math.max(
              ...areaPixelSums,
              ...areaPixelSums.map((sum, i) => sum + (canvasBandSums[i] || 0))
            ) || 1; // Use 1 as fallback to avoid division by zero

            return (
              <div 
                key={index} 
                className={`metric-button ${selectedBands.includes(index + 1) ? 'selected' : ''}`}
                onClick={handleBandChange(index + 1)}
                style={{
                  backgroundColor: selectedBands.includes(index + 1) ? SELECTED_BLUE_BG : '#ffffff',
                  borderColor: selectedBands.includes(index + 1) ? SELECTED_BLUE : BORDER_COLOR,
                  borderWidth: '2px',
                  borderStyle: 'solid',
                  padding: '10px 15px',
                  margin: '5px 0',
                  width: '100%',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  transform: selectedBands.includes(index + 1) ? 'scale(1.02)' : 'scale(1)',
                  boxShadow: selectedBands.includes(index + 1) ? '0 2px 8px rgba(0,0,0,0.1)' : 'none',
                  transition: 'all 0.2s ease-in-out'
                }}
              >
                <div className="metric-title" style={{ 
                  color: selectedBands.includes(index + 1) ? SELECTED_BLUE : '#000',
                  fontWeight: selectedBands.includes(index + 1) ? '500' : 'normal'
                }}>
                  {description}
                </div>
                <div className="metric-values" style={{ 
                  color: selectedBands.includes(index + 1) ? '#2196f3cc' : '#666'
                }}>
                  {activeSelection && (
                    <div className="area-value">
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>Base Sum: {areaPixelSums[index] || 0}</span>
                        <span>With Modifications: {(areaPixelSums[index] || 0) + canvasBandSums[index] || 0}</span>
                      </div>
                      <div className="metric-progress" style={{ position: 'relative', height: '20px' }}>
                        {/* Base value bar */}
                        <div 
                          className="metric-progress-fill"
                          style={{
                            position: 'absolute',
                            width: `${(areaPixelSums[index] || 0) / maxValue * 100}%`,
                            height: '100%',
                            backgroundColor: `rgb(200, 200, 200)`, // Gray for base value
                            transition: 'width 0.3s ease'
                          }}
                        />
                        {/* Modification bar - can go beyond or reduce from base */}
                        <div 
                          className="metric-progress-fill"
                          style={{
                            position: 'absolute',
                            width: `${((areaPixelSums[index] || 0) + canvasBandSums[index] || 0) / maxValue * 100}%`,
                            height: '100%',
                            backgroundColor: canvasBandSums[index] >= 0 
                              ? `rgba(0, 255, 0, 0.5)`  // Green for positive change
                              : `rgba(255, 0, 0, 0.5)`, // Red for negative change
                            transition: 'width 0.3s ease'
                          }}
                        />
                        {/* Marker line at base value */}
                        <div 
                          style={{
                            position: 'absolute',
                            left: `${(areaPixelSums[index] || 0) / maxValue * 100}%`,
                            height: '100%',
                            width: '2px',
                            backgroundColor: '#000',
                            zIndex: 2
                          }}
                        />
                      </div>
                    </div>
                  )}
                  <div className="brush-value">
                    Brush Total: {canvasBandSums[index] || 0}
                  </div>
                  <div className="hover-value">
                    m² pixel value: {pixelValues[index] || 0}
                    {hoverIntervention && (
                      <>
                        <br />
                        Intervention: {hoverIntervention.name}
                        <br />
                        Intervention value: {hoverIntervention.values[index]?.toFixed(2) || 0}
                      </>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        {/* Replace map container with MapCore */}
        <MapCore
          selectedCategory={selectedCategory}
          selectedBands={selectedBands}
          onMapReady={handleMapReady}
          currentBrush={currentBrush}
          brushSize={brushSize}
          selectedArea={selectedArea}
          drawOnCanvas={drawOnCanvas}
          onHover={(coordinate: number[]) => {
            if (!mapInstanceRef.current) return;
            
            // Get base pixel values
            const event = {
              clientX: coordinate[0],
              clientY: coordinate[1]
            };
            const pixel = mapInstanceRef.current.getEventPixel(event);
            if (pixel) {
              const cogLayer = mapInstanceRef.current.getLayers().getArray()[1] as WebGLTileLayer;
              if (cogLayer && cogLayer instanceof WebGLTileLayer) {
                try {
                  const data = cogLayer.getData(pixel);
                  if (data) {
                    const pixelData = Array.isArray(data) ? data : Array.from(data as Float32Array);
                    setPixelValues(pixelData);
                  }
                } catch (error: unknown) {
                                  }
              }
              
              // Get intervention data
              const intervention = getInterventionAtPixel(coordinate);
              if (intervention) {
                setHoverIntervention({
                  name: intervention.brush.name,
                  values: intervention.values
                });
              } else {
                setHoverIntervention({ name: '', values: new Array(bandDescriptions.length).fill(0) });
              }
            }
          }}
        >
          {/* children components */}
        </MapCore>

        {/* Right panel */}
        <div className="brush-controls">
          {/* Add this button before the brush size control */}
          <button
            onClick={() => activeSelection ? clearSelection() : toggleSelectionMode()}
            className={`overview-button ${isSelectionMode ? 'active' : ''}`}
            style={{
              marginBottom: '16px',
              padding: '8px 12px',
              width: '100%',
              borderRadius: '4px',
              border: `1px solid ${BORDER_COLOR}`,
              backgroundColor: isSelectionMode ? SELECTED_BLUE : 'white',
              color: isSelectionMode ? 'white' : SELECTED_BLUE,
              cursor: 'pointer'
            }}
          >
            {isSelectionMode ? 'Drawing Area...' : 
             activeSelection ? 'Clear Selection' : 'Draw Selected Area'}
          </button>

          {/* Add sorting controls */}
          <div style={{ marginBottom: '15px' }}>
            <select 
              value={sortBand === null ? '' : (typeof sortBand === 'string' ? sortBand : sortBand)}
              onChange={(e) => {
                const value = e.target.value;
                setSortBand(value === '' ? null : value === 'cost' ? 'cost' : Number(value));
              }}
              style={{
                width: '70%',
                padding: '8px',
                marginRight: '8px',
                borderRadius: '4px',
                border: `1px solid ${BORDER_COLOR}`
              }}
            >
              <option value="">No sorting</option>
              <option value="cost">Sort by Cost</option>
              <optgroup label="Sort by Band">
                {bandDescriptions.map((description, index) => (
                  <option key={index} value={index}>
                    {description}
                  </option>
                ))}
              </optgroup>
            </select>
            
            <button
              onClick={() => setSortDirection(prev => prev === 'asc' ? 'desc' : 'asc')}
              style={{
                padding: '8px',
                borderRadius: '4px',
                border: `1px solid ${BORDER_COLOR}`,
                background: 'white',
                cursor: 'pointer'
              }}
            >
              {sortDirection === 'desc' ? '↓' : '↑'}
            </button>
          </div>

          <h3>Interventions</h3>
          <div className="brush-size-control">
            <label>Brush Size: {brushSize}m²</label>
            <input 
              type="range" 
              min="1"      // Changed from 50
              max="50"     // Changed from 500
              step="1"     // Changed from 10
              value={brushSize} 
              onChange={(e) => setBrushSize(Number(e.target.value))} 
              style={{
                width: '100%',
                margin: '10px 0'
              }}
            />
          </div>
          {/* Update this section to use getSortedBrushes() */}
          {getSortedBrushes()?.map((brush: Brush) => {
            const isSelected = currentBrush?.name === brush.name;
            const brushArea = activeSelection ? (canvasBrushAreas[brush.name] || 0) : 0;
            const brushTotalCost = activeSelection ? brushArea * brush.cost : 0;
            const maxCost = activeSelection ? Math.max(...Object.entries(canvasBrushAreas)
              .map(([name, area]) => {
                const b = brushes[selectedCategory]?.find(b => b.name === name);
                return (b?.cost || 0) * area;
              })) : 0;

            // Get the value for the selected band (if any)
            const bandValue = sortBand !== null && typeof sortBand === 'number' && brush.values ? brush.values[sortBand] : null;

            return (
              <button 
                key={brush.name} 
                onClick={() => selectBrush(brush)}
                className={`brush-button ${isSelected ? 'active' : ''}`}
                disabled={!activeSelection}
                style={{
                  backgroundColor: brush.color || '#ffffff',
                  borderColor: isSelected ? SELECTED_BLUE : BORDER_COLOR,
                  borderWidth: isSelected ? '3px' : '2px',
                  borderStyle: 'solid',
                  padding: '10px 15px',
                  margin: '5px 0',
                  width: '100%',
                  textAlign: 'left',
                  borderRadius: '4px',
                  cursor: activeSelection ? 'pointer' : 'not-allowed',
                  transform: isSelected ? 'scale(1.02)' : 'scale(1)',
                  boxShadow: isSelected ? '0 2px 8px rgba(0,0,0,0.1)' : 'none',
                  transition: 'all 0.2s ease-in-out',
                  opacity: activeSelection ? 1 : 0.5
                }}
              >
                <div style={{ 
                  display: 'flex', 
                  flexDirection: 'column',
                  gap: '8px'
                }}>
                  <div style={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center'
                  }}>
                    <span style={{ 
                      color: '#000',
                      fontWeight: isSelected ? '500' : 'normal'
                    }}>
                      {brush.name}
                    </span>
                    <div style={{
                      display: 'flex',
                      gap: '12px',
                      alignItems: 'center',
                      fontSize: '0.9em',
                      color: '#666'
                    }}>
                      {bandValue !== null && (
                        <span style={{
                          padding: '2px 6px',
                          backgroundColor: 'rgba(0,0,0,0.05)',
                          borderRadius: '4px'
                        }}>
                          {bandValue.toFixed(1)}
                        </span>
                      )}
                      <span>${brush.cost}/m²</span>
                    </div>
                  </div>

                  {/* Area and total section */}
                  {activeSelection && brushArea > 0 && (
                    <div>
                      <div style={{ 
                        display: 'flex', 
                        justifyContent: 'space-between',
                        fontSize: '0.85em',
                        color: '#666'
                      }}>
                        <span>Area: {brushArea.toFixed(1)}m²</span>
                        <span>Total: ${brushTotalCost.toFixed(2)}</span>
                      </div>
                      <div className="metric-progress" style={{ position: 'relative', height: '8px', marginTop: '4px' }}>
                        <div 
                          className="metric-progress-fill"
                          style={{
                            position: 'absolute',
                            width: `${maxCost > 0 ? (brushTotalCost / maxCost) * 100 : 0}%`,
                            height: '100%',
                            backgroundColor: brushTotalCost >= 0 
                              ? `rgba(0, 255, 0, 0.5)`
                              : `rgba(255, 0, 0, 0.5)`,
                            transition: 'width 0.3s ease'
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </button>
            );
          })}
 
        </div>
      </div>

      {/* Footer with centered search */}
      <div style={{
        padding: '10px',
        borderTop: '1px solid #ccc',
        backgroundColor: '#f8f8f8',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <div className="search-container">
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                search(searchQuery);
              }
            }}
            placeholder="Search for a location..."
            className="search-input"
          />
        </div>
      </div>

      {/* Add this new button */}
      <button 
        onClick={handleGenerateAndDownloadReport}
        disabled={isGeneratingReport || !selectedArea || reportPending}
        className="generate-report-button"
      >
        {isGeneratingReport ? 'Generating PDF...' : 
         reportPending ? 'Processing Payment...' : 
         'Generate Report (£1)'}
      </button>
      {reportData && (
        <div style={{ marginTop: '10px' }}>
          <PDFDownloadLink
            document={<PDFReportLayout {...reportData} />}
            fileName="urban-biome-report.pdf"
            style={{
              textDecoration: 'none',
              padding: '10px',
              color: '#4a4a4a',
              backgroundColor: '#f0f0f0',
              border: '1px solid #ddd',
              borderRadius: '4px'
            }}
          >
            Download Report PDF
          </PDFDownloadLink>
        </div>
      )}
      {showPaymentModal && (
        <PaymentModal
          amount={1} // £1.00 for report
          onSuccess={handlePaymentSuccess}
          onCancel={() => {
            setShowPaymentModal(false);
            setReportPending(false);
          }}
        />
      )}
    </div>
  );
}

export default App;  