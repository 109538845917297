import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { CONFIG } from '../config';

const stripePromise = loadStripe('pk_live_51PqvnpCyn6OZ4btkds9ruyxbZ7eDt2xXDhcfx1o0JfTHSzLZR0Q4DxszQv5RaAqvOdGNLcWxbtS5fjjLA98kZKdV00Wd6BUG2b');

interface PaymentModalProps {
  onSuccess: () => void;
  onCancel: () => void;
  amount: number;
}

const PaymentForm = ({ onSuccess, onCancel, amount }: PaymentModalProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!stripe || !elements) return;

    setIsProcessing(true);
    setErrorMessage(null);

    try {
      const result = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: window.location.origin,
        },
        redirect: 'if_required'
      });

      if (result.error) {
        setErrorMessage(result.error.message || 'Payment failed');
      } else {
        onSuccess();
      }
    } catch (error) {
      setErrorMessage('An unexpected error occurred');
      console.error('Payment error:', error);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="payment-modal">
      <h2>Generate Report</h2>
      <p>Cost: £{(amount).toFixed(2)}</p>
      <form onSubmit={handleSubmit}>
        <PaymentElement />
        {errorMessage && (
          <div className="error-message" style={{ color: 'red', margin: '10px 0' }}>
            {errorMessage}
          </div>
        )}
        <div className="button-group">
          <button type="submit" disabled={!stripe || isProcessing}>
            {isProcessing ? 'Processing...' : 'Pay and Generate'}
          </button>
          <button type="button" onClick={onCancel}>Cancel</button>
        </div>
      </form>
    </div>
  );
};

export const PaymentModal = (props: PaymentModalProps) => {
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const createPaymentIntent = async () => {
      try {
        const response = await fetch('https://stripe-worker.rofor8.workers.dev/create-payment-intent', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            amount: props.amount
          }),
        });
        
        if (!response.ok) {
          throw new Error('Failed to create payment intent');
        }

        const data = await response.json();
        if (data.error) {
          throw new Error(data.error);
        }
        
        setClientSecret(data.clientSecret);
      } catch (error) {
        setError(error instanceof Error ? error.message : 'An error occurred');
        console.error('Error creating payment intent:', error);
      }
    };

    createPaymentIntent();
  }, [props.amount]);

  return (
    <>
      <div className="modal-overlay" />
      <div className="payment-modal">
        {error ? (
          <>
            <h2>Error</h2>
            <p style={{ color: 'red' }}>{error}</p>
            <button onClick={props.onCancel}>Close</button>
          </>
        ) : !clientSecret ? (
          <div>Loading...</div>
        ) : (
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <PaymentForm {...props} />
          </Elements>
        )}
      </div>
    </>
  );
};