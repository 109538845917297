import React from 'react';
import { BlobProvider } from '@react-pdf/renderer';
import { PDFReportLayout } from './PDFReportLayout';

interface PDFGeneratorProps {
  reportData: {
    baseMapImage: string;
    bandImages: Array<{
      base: string;
      withInterventions: string;
    }>;
    bandDescriptions: string[];
    interventions: Array<{
      name: string;
      area: number;
      cost: number;
      color: string;
      values: number[];
    }>;
    bandTotals: number[];
    totalCost: number;
    selectedArea: number;
    dateGenerated: Date;
    projectName: string;
    location: string;
  };
  onComplete: () => void;
}

export const PDFGenerator: React.FC<PDFGeneratorProps> = ({ reportData, onComplete }) => {
  return (
    <BlobProvider document={<PDFReportLayout {...reportData} />}>
      {({ blob, url }) => {
        if (blob && url) {
          const a = document.createElement('a');
          a.href = url;
          a.download = 'urban-biome-report.pdf';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          onComplete();
        }
        return null;
      }}
    </BlobProvider>
  );
};