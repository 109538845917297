/* eslint-disable @typescript-eslint/no-unused-vars */
import { Map } from 'ol';
import WebGLTileLayer from 'ol/layer/WebGLTile';
import { GeoTIFF } from 'ol/source';
import { Brush } from '../types';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { PDFGenerator } from '../components/PDFGenerator';

interface BandImage {
  base: string;
  withInterventions: string;
}

interface ReportConfig {
  projectName: string;
  location: string;
  selectedArea: number;
  dateGenerated: Date;
  bandTotals: number[];
  totalCost: number;
}

export class ReportGenerator {
  constructor(
    private mapInstance: Map,
    private bandDescriptions: string[],
    private brushes: Brush[],
    private brushAreas: { [key: string]: number },
    private canvasLayers: { [key: string]: HTMLCanvasElement },
    private config?: ReportConfig
  ) {}

  private async captureBaseMap(): Promise<string> {
    try {
      const mapCanvas = this.mapInstance.getTargetElement().querySelector('canvas');
      if (!mapCanvas) return '';

      const exportCanvas = document.createElement('canvas');
      const context = exportCanvas.getContext('2d');
      if (!context) return '';

      exportCanvas.width = mapCanvas.width;
      exportCanvas.height = mapCanvas.height;
      context.drawImage(mapCanvas, 0, 0);

      return exportCanvas.toDataURL('image/png');
    } catch (error) {
      console.error('Error capturing base map:', error);
      return '';
    }
  }

  private async captureBandImages(): Promise<BandImage[]> {
    const layers = this.mapInstance.getLayers().getArray();
    const cogLayer = layers.find(layer => layer instanceof WebGLTileLayer) as WebGLTileLayer;
    const vectorLayers = layers.filter(layer => !(layer instanceof WebGLTileLayer));
    
    if (!cogLayer) return [];

    // Store original states
    const originalStyle = cogLayer.get('style');
    const originalVisibilities = vectorLayers.map(layer => ({
      layer,
      visible: layer.getVisible()
    }));

    try {
      const results: BandImage[] = [];
      
      // Process bands sequentially instead of in parallel
      for (let bandIndex = 0; bandIndex < this.bandDescriptions.length; bandIndex++) {
        // Hide vector layers during band capture
        vectorLayers.forEach(layer => layer.setVisible(false));
        cogLayer.setVisible(true);

        // Hide all canvas layers initially
        Object.values(this.canvasLayers).forEach(canvas => {
          canvas.style.display = 'none';
        });

        const baseCanvas = document.createElement('canvas');
        const baseContext = baseCanvas.getContext('2d');
        if (!baseContext) continue;

        const size = this.mapInstance.getSize();
        if (!size) continue;

        baseCanvas.width = size[0];
        baseCanvas.height = size[1];

        // Set style for current band
        cogLayer.setStyle({
          color: [
            'array',
            ['band', bandIndex + 1],
            ['band', bandIndex + 1],
            ['band', bandIndex + 1],
            1
          ]
        });
        console.log(`Capturing band ${bandIndex + 1} with description: ${this.bandDescriptions[bandIndex]}`);

        // Wait for render to complete with longer timeout
        await new Promise<void>(resolve => {
          const checkRender = () => {
            this.mapInstance.once('rendercomplete', () => {
              // Add additional delay after render complete
              setTimeout(resolve, 500);
            });
            this.mapInstance.render();
          };
          
          // Initial delay before starting render
          setTimeout(checkRender, 200);
        });

        // Capture base image (COG only)
        const mapCanvas = this.mapInstance.getTargetElement().querySelector('canvas');
        if (!mapCanvas) continue;
        baseContext.drawImage(mapCanvas, 0, 0);

        // Create intervention canvas
        const interventionCanvas = document.createElement('canvas');
        const interventionContext = interventionCanvas.getContext('2d');
        if (!interventionContext) continue;

        interventionCanvas.width = size[0];
        interventionCanvas.height = size[1];

        // Copy base content
        interventionContext.drawImage(baseCanvas, 0, 0);

        // Show and add the specific band's intervention layer
        const bandCanvas = this.canvasLayers[`band_${bandIndex}`];
        const baseCanvasLayer = this.canvasLayers['base']; // For selected area
        
        if (baseCanvasLayer) {
          baseCanvasLayer.style.display = 'block';
          interventionContext.drawImage(baseCanvasLayer, 0, 0);
        }
        
        if (bandCanvas) {
          bandCanvas.style.display = 'block';
          interventionContext.drawImage(bandCanvas, 0, 0);
        }

        // Wait for canvas updates
        await new Promise(resolve => setTimeout(resolve, 200));

        results.push({
          base: baseCanvas.toDataURL('image/png'),
          withInterventions: interventionCanvas.toDataURL('image/png')
        });
      }

      return results;
    } finally {
      // Restore original states
      if (originalStyle) {
        cogLayer.setStyle(originalStyle);
      }
      
      originalVisibilities.forEach(({ layer, visible }) => {
        layer.setVisible(visible);
      });
    }
  }

  async generateReport(): Promise<void> {
    try {
      const baseMapImage = await this.captureBaseMap();
      const bandImages = await this.captureBandImages();

      const reportDataObj = {
        baseMapImage,
        bandImages,
        bandDescriptions: this.bandDescriptions,
        interventions: this.brushes.map(brush => ({
          name: brush.name,
          area: this.brushAreas[brush.name] || 0,
          cost: brush.cost,
          color: brush.color,
          values: brush.values || []
        })),
        bandTotals: this.config?.bandTotals || [],
        totalCost: this.config?.totalCost || 0,
        selectedArea: this.config?.selectedArea || 0,
        dateGenerated: this.config?.dateGenerated || new Date(),
        projectName: this.config?.projectName || "Urban Development Analysis",
        location: this.config?.location || "Custom Area"
      };

      // Create container for React rendering
      const container = document.createElement('div');
      document.body.appendChild(container);
      const root = createRoot(container);

      return new Promise<void>((resolve, reject) => {
        try {
          root.render(
            React.createElement(PDFGenerator, {
              reportData: reportDataObj,
              onComplete: () => {
                setTimeout(() => {
                  root.unmount();
                  document.body.removeChild(container);
                  resolve();
                }, 1000);
              }
            })
          );
        } catch (error) {
          reject(error);
        }
      });

    } catch (error) {
      console.error('Error generating report:', error);
      throw error;
    }
  }
}