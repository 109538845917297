import { useCallback } from 'react';
import { Map, MapBrowserEvent } from 'ol';
import { Brush, TypedArray } from '../types';
import Vector from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Feature } from 'ol';
import { Geometry, Polygon, Circle } from 'ol/geom';
import { getPointResolution } from 'ol/proj';
import { Style, Fill, Stroke } from 'ol/style';
import WebGLTileLayer from 'ol/layer/WebGLTile';

interface DrawingSystemProps {
  mapInstance: Map | null;
  isDrawing: boolean;
  setIsDrawing: (drawing: boolean) => void;
  currentBrush: Brush | null;
  brushSize: number;
  selectedArea: Polygon | null;
  activeSelection: boolean;
  selectedCategory: string;
  canvasLayers: { [key: string]: HTMLCanvasElement };
  setAreaPixelSums: (sums: number[]) => void;
  setMaskVectorLayer: (layer: Vector<VectorSource<Feature<Geometry>>> | null) => void;
}

export function useDrawingSystem({
  mapInstance,
  isDrawing,
  setIsDrawing,
  currentBrush,
  brushSize,
  selectedArea,
  activeSelection,
  selectedCategory,
  canvasLayers,
  setAreaPixelSums,
  setMaskVectorLayer
}: DrawingSystemProps) {
  
  // Convert brush size from m² to radius in map units (meters)
  const getBrushRadius = useCallback((brushSizeM2: number, coordinate: number[]) => {
    // Area = πr², so r = √(area/π)
    const radiusInMeters = Math.sqrt(brushSizeM2 / Math.PI);
    
    if (!mapInstance) return radiusInMeters;

    // Convert meters to map units considering the projection scale factor
    const projection = mapInstance.getView().getProjection();
    const resolution = mapInstance.getView().getResolution() || 1;
    
    // Get the point resolution at the click location
    const pointResolution = getPointResolution(
      projection,
      resolution,
      coordinate
    );

    // Scale the radius based on the point resolution
    return radiusInMeters / pointResolution;
  }, [mapInstance]);

  const draw = useCallback((event: MapBrowserEvent<UIEvent>) => {
    if (!mapInstance || !currentBrush || !activeSelection) return;

    const coordinate = event.coordinate;
    const pixel = mapInstance.getPixelFromCoordinate(coordinate);
    
    const interventionLayer = mapInstance.getLayers().getArray()
      .find(layer => 
        layer instanceof WebGLTileLayer && 
        layer.getZIndex() === 2
      ) as WebGLTileLayer;

    if (interventionLayer) {
      const data = interventionLayer.getData(pixel) as TypedArray;
      if (data && data[(currentBrush.index || 0)] === 0) {
        return; // Don't draw in masked areas
      }
    }

    // Rest of your drawing code...
    const radiusInMapUnits = getBrushRadius(brushSize, coordinate);
    
    // Create and add circle feature with permanent style
    const modificationLayer = mapInstance.getLayers().getArray()
      .find(layer => layer instanceof Vector) as Vector<VectorSource<Feature<Geometry>>>;
      
    if (modificationLayer) {
      const source = modificationLayer.getSource();
      if (source) {
        const circleFeature = new Feature({
          geometry: new Circle(coordinate, radiusInMapUnits)
        });

        circleFeature.setStyle(new Style({
          fill: new Fill({
            color: currentBrush.color || 'rgba(255, 0, 0, 0.4)'
          }),
          stroke: new Stroke({
            color: 'rgba(0, 0, 0, 0.8)',
            width: 1
          })
        }));

        source.addFeature(circleFeature);
      }
    }
  }, [
    mapInstance,
    currentBrush,
    brushSize,
    activeSelection,
    getBrushRadius
  ]);

  const startDrawing = useCallback(() => {
    if (!mapInstance || !currentBrush) return;
    setIsDrawing(true);
  }, [mapInstance, currentBrush, setIsDrawing]);

  const stopDrawing = useCallback(() => {
    if (!mapInstance) return;
    setIsDrawing(false);
  }, [mapInstance, setIsDrawing]);

  return { draw, startDrawing, stopDrawing };
}