import { useEffect, useState, useCallback, useRef, Dispatch, SetStateAction } from 'react';
import { Map } from 'ol';
import { Brush } from '../types';

interface CanvasSystemProps {
  mapInstance: Map | null;
  selectedBands: number[];
  bandDescriptions: string[];
  currentBrush: Brush | null;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface CanvasSystemReturn {
  canvasLayers: { [key: string]: HTMLCanvasElement };
  setCanvasLayers: Dispatch<SetStateAction<{ [key: string]: HTMLCanvasElement }>>;
  drawOnCanvas: (coordinate: number[], brushSize: number) => void;
  clearCanvas: () => void;
  bandSums: number[];
  brushAreas: { [key: string]: number };
  calculateBandSums: () => void;
  getInterventionAtPixel: (coordinate: number[]) => { brush: Brush, value: number } | null;
}

export const useCanvasSystem = ({ 
  mapInstance, 
  selectedBands, 
  bandDescriptions,
  currentBrush 
}: CanvasSystemProps) => {
  const [canvasLayers, setCanvasLayers] = useState<{[key: string]: HTMLCanvasElement}>({});
  const [bandSums, setBandSums] = useState<number[]>(new Array(bandDescriptions.length).fill(0));
  const [brushAreas, setBrushAreas] = useState<{[key: string]: number}>({});
  
  const modifiedCellsRef = useRef<{
    [key: string]: {
      brush: Brush;
      value: number;
      values: number[]; // Store all band values for this cell
    }
  }>({});

  // Calculate sums for all bands
  const calculateBandSums = useCallback(() => {
    const sums = new Array(bandDescriptions.length).fill(0);
    
    Object.values(modifiedCellsRef.current).forEach(cell => {
      if (cell.brush.values) {
        cell.brush.values.forEach((value, index) => {
          sums[index] += value;
        });
      }
    });
    
    setBandSums(sums);
  }, [bandDescriptions.length]);

  // Calculate areas for each brush
  const calculateBrushAreas = useCallback(() => {
    const areas: {[key: string]: number} = {};
    
    Object.values(modifiedCellsRef.current).forEach(cell => {
      const brushName = cell.brush.name;
      if (brushName) {
        areas[brushName] = (areas[brushName] || 0) + 1; // Each cell is 1m²
      }
    });
    
    setBrushAreas(areas);
  }, []);

  // Draw function for all canvases
  const redrawCanvases = useCallback(() => {
    if (!mapInstance || !canvasLayers) return;
    
    const size = mapInstance.getSize();
    if (!size) return;
    const resolution = mapInstance.getView().getResolution() || 1;
    const cellSize = 1 / resolution; // size of 1m² in pixels

    Object.entries(canvasLayers).forEach(([layerKey, canvas]) => {
      const ctx = canvas.getContext('2d');
      if (!ctx) return;

      ctx.clearRect(0, 0, size[0], size[1]);

      // Draw all modified cells
      Object.entries(modifiedCellsRef.current).forEach(([cellKey, cell]) => {
        const [x, y] = cellKey.split(',').map(Number);
        
        if (layerKey === 'base') {
          ctx.fillStyle = cell.brush.color || 'rgba(0, 0, 0, 0.3)';
        } else {
          const bandIndex = parseInt(layerKey.replace('band_', ''));
          if (cell.brush.values && bandIndex < cell.brush.values.length) {
            const value = cell.brush.values[bandIndex];
            const opacity = value / 255;
            ctx.fillStyle = `rgba(0, 0, 0, ${opacity})`;
          }
        }

        ctx.fillRect(x, y, cellSize, cellSize); // Fill 1m² cell
      });
    });
  }, [mapInstance, canvasLayers]);

  const drawOnCanvas = useCallback((coordinate: number[], brushSize: number) => {
    if (!mapInstance || !currentBrush) return;

    const pixel = mapInstance.getPixelFromCoordinate(coordinate);
    if (!pixel) return;

    const resolution = mapInstance.getView().getResolution() || 1;
    const cellSize = 1 / resolution;
    const halfBrush = Math.floor(brushSize / 2);
    
    for (let dx = -halfBrush; dx <= halfBrush; dx++) {
      for (let dy = -halfBrush; dy <= halfBrush; dy++) {
        const cellX = Math.floor((pixel[0] + dx * cellSize) / cellSize) * cellSize;
        const cellY = Math.floor((pixel[1] + dy * cellSize) / cellSize) * cellSize;
        
        const cellKey = `${cellX},${cellY}`;
        modifiedCellsRef.current[cellKey] = {
          brush: currentBrush,
          value: currentBrush.values?.[0] || 0,
          values: currentBrush.values || []
        };
      }
    }

    redrawCanvases();
    calculateBandSums();
    calculateBrushAreas();
  }, [mapInstance, currentBrush, redrawCanvases, calculateBandSums, calculateBrushAreas]);

  // Initialize canvases
  useEffect(() => {
    if (!mapInstance) return;

    const size = mapInstance.getSize();
    if (!size) return;

    const mapViewport = mapInstance.getViewport();
    const newCanvasLayers: {[key: string]: HTMLCanvasElement} = {};

    // Create base canvas
    const baseCanvas = document.createElement('canvas');
    baseCanvas.className = 'map-canvas-layer';
    baseCanvas.width = size[0];
    baseCanvas.height = size[1];
    baseCanvas.style.position = 'absolute';
    baseCanvas.style.top = '0';
    baseCanvas.style.left = '0';
    baseCanvas.style.pointerEvents = 'none';
    baseCanvas.style.zIndex = '10';
    baseCanvas.setAttribute('crossOrigin', 'anonymous');
    newCanvasLayers['base'] = baseCanvas;

    // Create canvas for each band
    bandDescriptions.forEach((_, index) => {
      const bandCanvas = document.createElement('canvas');
      bandCanvas.className = 'map-canvas-layer';
      bandCanvas.width = size[0];
      bandCanvas.height = size[1];
      bandCanvas.style.position = 'absolute';
      bandCanvas.style.top = '0';
      bandCanvas.style.left = '0';
      bandCanvas.style.pointerEvents = 'none';
      bandCanvas.style.zIndex = '10';
      bandCanvas.style.display = 'none';
      bandCanvas.setAttribute('crossOrigin', 'anonymous');
      newCanvasLayers[`band_${index}`] = bandCanvas;
    });

    // Add canvases to map viewport
    Object.values(newCanvasLayers).forEach(canvas => {
      mapViewport.appendChild(canvas);
    });

    setCanvasLayers(newCanvasLayers);

    return () => {
      Object.values(newCanvasLayers).forEach(canvas => {
        canvas.remove();
      });
    };
  }, [mapInstance, bandDescriptions]);

  // Set up map render handler in a separate effect
  useEffect(() => {
    if (!mapInstance) return;

    const handleMapRender = () => {
      requestAnimationFrame(redrawCanvases);
    };

    mapInstance.on('postrender', handleMapRender);

    return () => {
      mapInstance.un('postrender', handleMapRender);
    };
  }, [mapInstance, redrawCanvases]);

  // Handle canvas visibility
  useEffect(() => {
    if (!canvasLayers || Object.keys(canvasLayers).length === 0) return;
    
    try {
      // Hide ALL canvases first
      Object.entries(canvasLayers).forEach(([_, canvas]) => {
        if (canvas && canvas.style) {
          canvas.style.display = 'none';
        }
      });
      
      // Then show ONLY the selected canvas
      if (selectedBands.length === 0) {
        // Show base canvas when no band is selected
        const baseCanvas = canvasLayers['base'];
        if (baseCanvas && baseCanvas.style) {
          baseCanvas.style.display = 'block';
        }
      } else {
        // Show only the selected band's canvas
        selectedBands.forEach(bandIndex => {
          const selectedCanvas = canvasLayers[`band_${bandIndex - 1}`];
          if (selectedCanvas && selectedCanvas.style) {
            selectedCanvas.style.display = 'block';
          }
        });
      }
    } catch (error) {
      console.error('Error updating canvas visibility:', error);
    }
  }, [selectedBands, canvasLayers]);

  // Modify clearCanvas to reset all drawing-related data
  const clearCanvas = useCallback(() => {
    modifiedCellsRef.current = {};
    setBandSums(new Array(bandDescriptions.length).fill(0));
    setBrushAreas({}); // Reset brush areas
    redrawCanvases();
  }, [redrawCanvases, bandDescriptions.length]);

  // Add this new function inside useCanvasSystem
  const getInterventionAtPixel = useCallback((coordinate: number[]) => {
    if (!mapInstance) return null;
    
    const pixel = mapInstance.getPixelFromCoordinate(coordinate);
    if (!pixel) return null;

    const resolution = mapInstance.getView().getResolution() || 1;
    const cellSize = 1 / resolution;
    const cellX = Math.floor(pixel[0] / cellSize) * cellSize;
    const cellY = Math.floor(pixel[1] / cellSize) * cellSize;
    
    const cellKey = `${cellX},${cellY}`;
    return modifiedCellsRef.current[cellKey] || null;
  }, [mapInstance]);

  return {
    canvasLayers,
    setCanvasLayers,
    drawOnCanvas,
    clearCanvas,
    bandSums,
    brushAreas,
    calculateBandSums,
    getInterventionAtPixel
  };
};