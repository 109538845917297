import React, { useEffect, useRef } from 'react';
import Map from 'ol/Map';
import View from 'ol/View';
import WebGLTileLayer from 'ol/layer/WebGLTile';
import VectorTileLayer from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';
import MVT from 'ol/format/MVT';
import GeoTIFF from 'ol/source/GeoTIFF';
import { fromLonLat } from 'ol/proj';
import { Style, Fill, Text, Stroke } from 'ol/style';
import { FeatureLike } from 'ol/Feature';
import { StyleFunction } from 'ol/style/Style';
import { CONFIG } from '../config';
import { DragPan } from 'ol/interaction';
import { WebGLDrawingLayer } from './WebGLDrawingLayer';
import { MaskLayer } from './MaskLayer';
import { Polygon } from 'ol/geom';
import { Brush } from '../types';
import { MapBrowserEvent } from 'ol';
import { getAssetUrl } from '../utilities';
import { getCategoryFile } from '../utilities';
import { CATEGORIES } from '../constants';
import { PMTiles } from 'pmtiles';
import VectorTile from 'ol/VectorTile';
import RenderFeature from 'ol/render/Feature';

interface MapCoreProps {
  selectedCategory: string;
  selectedBands: number[];
  onMapReady: (map: Map) => void;
  currentBrush: Brush | null;
  brushSize: number;
  selectedArea: Polygon | null;
  drawOnCanvas: (coordinate: number[], brushSize: number) => void;
  onHover?: (coordinate: number[]) => void;
  children?: React.ReactNode;
}

export function MapCore({ selectedCategory, selectedBands, onMapReady, children, currentBrush, brushSize, selectedArea, drawOnCanvas, onHover }: MapCoreProps) {
  const mapRef = useRef<HTMLDivElement>(null);
  const mapInstanceRef = useRef<Map | null>(null);

  // Initial map setup
  useEffect(() => {
    if (mapRef.current && !mapInstanceRef.current) {
      const bristolCoords = fromLonLat([-2.587910, 51.456314]);
      
      // Set up PMTiles protocol
      const pmtilesUrl = CONFIG.isProduction 
        ? `${CONFIG.apiUrl}/vector/bristol.pmtiles`
        : `/bucket/vector/bristol.pmtiles`;
      
      const p = new PMTiles(pmtilesUrl);
      const mvtFormat = new MVT();
      
      // Create vector tile layer using PMTiles
      const vectorLayer = new VectorTileLayer({
        source: new VectorTileSource({
          url: pmtilesUrl,
          format: mvtFormat,
          maxZoom: 16,
          tileLoadFunction: async (tile, url) => {
            const vectorTile = tile as VectorTile<RenderFeature>;
            const coord = vectorTile.getTileCoord();
            console.log('[PMTiles] Loading tile:', coord);
            
            try {
              const response = await p.getZxy(coord[0], coord[1], coord[2]);
              console.log('[PMTiles] Response:', {
                hasData: !!response,
                dataSize: response?.data.byteLength,
                coord
              });
              
              if (response && response.data) {
                const features = mvtFormat.readFeatures(response.data, {
                  extent: vectorTile.extent,
                  featureProjection: 'EPSG:3857',
                });
                console.log('[PMTiles] Features loaded:', {
                  count: features.length,
                  firstFeature: features[0]?.getProperties(),
                  coord
                });
                
                vectorTile.setFeatures(features as RenderFeature[]);
              } else {
                console.warn('[PMTiles] No data for tile:', coord);
              }
            } catch (error) {
              console.error('[PMTiles] Error loading tile:', {
                error,
                coord,
                url: pmtilesUrl
              });
            }
          }
        }),
        style: createVectorStyle(),
        zIndex: 40,
        visible: true
      });

      // Add source event listeners
      vectorLayer.getSource()?.on('tileloadstart', (event) => {
        console.log('[PMTiles] Tile load started:', event.tile.getTileCoord());
      });

      vectorLayer.getSource()?.on('tileloadend', (event) => {
        console.log('[PMTiles] Tile loaded successfully:', {
          coord: event.tile.getTileCoord(),
          features: (event.tile as VectorTile<RenderFeature>).getFeatures()?.length
        });
      });

      vectorLayer.getSource()?.on('tileloaderror', (event) => {
        console.error('[PMTiles] Tile load error:', {
          coord: event.tile.getTileCoord()
        });
      });

      const lidarLayer = new WebGLTileLayer({
        source: new GeoTIFF({
          sources: [{
            url: CONFIG.isProduction 
              ? `${CONFIG.apiUrl}/lidar/bristol_lidar_cog.tif`
              : `/bucket/lidar/bristol_lidar_cog.tif`,
          }],
          normalize: false,
          interpolate: true,
        }),
        style: {
          color: [
            'interpolate',
            ['linear'],
            ['band', 1],
            0, [255, 255, 255, 1],     // Solid white for no data
            1, [50, 50, 50, 1],        // Solid dark grey
            128, [150, 150, 150, 1],   // Solid medium grey
            255, [250, 250, 250, 1]    // Solid light grey
          ]
        },
        opacity: 1,  // Full opacity
        zIndex: 10,
        visible: true,
        properties: {
          purpose: 'lidar'
        }
      });

      // Add source loading event handlers for debugging
      const lidarSource = lidarLayer.getSource();
      if (lidarSource) {
        lidarSource.on('tileloadstart', (event: any) => {
        });

        lidarSource.on('tileloadend', (event: any) => {
        });

        lidarSource.on('tileloaderror', (error: any) => {
          console.error('[LIDAR] Tile load error:', error);
        });
      }

      const cogLayer = new WebGLTileLayer({
        source: new GeoTIFF({
          sources: [
            {
              url: `${CONFIG.apiUrl}/base/${selectedCategory}_base.tif`,
            },
          ],
        }),
        style: {
          color: [
            'array',
            0, 0, 0, 0  // Start with transparent
          ]
        },
        visible: true,
        zIndex: 30
      });

      // Create the map with explicit layer ordering
      const map = new Map({
        target: mapRef.current,
        layers: [
          lidarLayer,     // Base layer (zIndex: 10)
          vectorLayer,    // Vector features (zIndex: 40)
          cogLayer,       // COG layer (zIndex: 30)
        ],
        view: new View({
          projection: 'EPSG:3857',
          center: bristolCoords,
          zoom: 15,
          maxZoom: 19,
          minZoom: 2
        }),
      });

      mapInstanceRef.current = map;
      onMapReady(map);
    }
  }, [selectedCategory, onMapReady]);

  // Initialize base layer
  useEffect(() => {
    if (!mapInstanceRef.current) return;
    
    const url = getAssetUrl('base', getCategoryFile(CATEGORIES, selectedCategory));
    const newCogLayer = new WebGLTileLayer({
      source: new GeoTIFF({
        sources: [{
          url: url,
        }],
        transition: 0,
        interpolate: false,
        wrapX: false,
      }),
      style: {
        color: selectedBands.length > 0 ? [
          'array',
          selectedBands.length === 1 
            ? ['band', selectedBands[0]]
            : ['/', ['+', ...selectedBands.map(band => ['band', band])], selectedBands.length],
          selectedBands.length === 1
            ? ['band', selectedBands[0]]
            : ['/', ['+', ...selectedBands.map(band => ['band', band])], selectedBands.length],
          selectedBands.length === 1
            ? ['band', selectedBands[0]]
            : ['/', ['+', ...selectedBands.map(band => ['band', band])], selectedBands.length],
          0.3
        ] : ['array', 0, 0, 0, 0]
      },
      visible: selectedBands.length > 0,
      zIndex: 30,
      properties: {
        purpose: 'cog'
      }
    });

    newCogLayer.on('error', (error) => {
    });

    try {
      mapInstanceRef.current.getLayers().setAt(2, newCogLayer);
    } catch (error) {
    }
  }, [selectedCategory, selectedBands]);

  // Update map interactions when brush is selected/deselected
  useEffect(() => {
    if (mapInstanceRef.current) {
      const interactions = mapInstanceRef.current.getInteractions().getArray();
      const dragPan = interactions.find(i => i instanceof DragPan);
      
      if (dragPan) {
        if (currentBrush) {
          // Disable panning when brush is active
          dragPan.setActive(false);
          
          if (mapRef.current) {
            mapRef.current.style.cursor = 'crosshair';
          }
        } else {
          // Enable panning when no brush is selected
          dragPan.setActive(true);
          
          if (mapRef.current) {
            mapRef.current.style.cursor = 'grab';
          }
        }
      }
    }
  }, [currentBrush]);

  // Add pointer move handler
  useEffect(() => {
    if (!mapInstanceRef.current || !onHover) return;

    const handlePointerMove = (event: MapBrowserEvent<UIEvent>) => {
      const coordinate = mapInstanceRef.current!.getCoordinateFromPixel(
        mapInstanceRef.current!.getEventPixel(event.originalEvent)
      );
      onHover(coordinate);
    };

    mapInstanceRef.current.on('pointermove', handlePointerMove);

    return () => {
      if (mapInstanceRef.current) {
        mapInstanceRef.current.un('pointermove', handlePointerMove);
      }
    };
  }, [onHover]);

  return (
    <div ref={mapRef} className="map-container">
      {mapInstanceRef.current && (
        <>
          {currentBrush && (
            <MaskLayer
              map={mapInstanceRef.current}
              selectedCategory={selectedCategory}
              currentBrush={currentBrush}
              zIndex={40}
            />
          )}
          <WebGLDrawingLayer
            map={mapInstanceRef.current}
            currentBrush={currentBrush}
            brushSize={brushSize}
            selectedArea={selectedArea}
            drawOnCanvas={drawOnCanvas}
          />
        </>
      )}
      {children}
    </div>
  );
}

function createVectorStyle(): StyleFunction {
  return (feature: FeatureLike, resolution: number): Style | Style[] | void => {
    const props = feature.getProperties();
    
    if (props.building && props.building !== 'null') {
      return new Style({
        fill: new Fill({
          color: 'rgba(252, 214, 164, 0.3)'
        }),
        stroke: new Stroke({
          color: 'rgba(242, 204, 154, 0.3)',
          width: 1
        })
      });
    }
    
    if (props.natural === 'water' || props.waterway) {
      return new Style({
        fill: new Fill({
          color: 'rgba(137, 207, 240, 0.3)'  // OS MasterMap-style blue with 0.3 opacity
        }),
        stroke: new Stroke({
          color: 'rgba(137, 207, 240, 0.3)',
          width: 1
        })
      });
    }
    
    return undefined;
  };
}