import { useEffect } from 'react';
import { Map } from 'ol';
import WebGLTileLayer from 'ol/layer/WebGLTile';
import GeoTIFF from 'ol/source/GeoTIFF';
import { getAssetUrl } from '../utilities';
import { Brush } from '../types';

interface MaskLayerProps {
  map: Map;
  selectedCategory: string;
  currentBrush: Brush | null;
  zIndex?: number;
}

export function MaskLayer({ map, selectedCategory, currentBrush, zIndex = 40 }: MaskLayerProps) {
  useEffect(() => {
    // Return early if no brush is selected
    if (!currentBrush) {
      // Remove any existing mask layer
      const existingMaskLayer = map.getLayers().getArray()
        .find(layer => layer instanceof WebGLTileLayer && layer.get('purpose') === 'mask');
      if (existingMaskLayer) {
        map.removeLayer(existingMaskLayer);
      }
      return;
    }

    const brushIndex = currentBrush.index || 0;

    // Remove any existing mask layer
    const existingMaskLayer = map.getLayers().getArray()
      .find(layer => layer instanceof WebGLTileLayer && layer.get('purpose') === 'mask');
    if (existingMaskLayer) {
      map.removeLayer(existingMaskLayer);
    }

    const maskLayer = new WebGLTileLayer({
      source: new GeoTIFF({
        sources: [{
          url: getAssetUrl('mask', `${selectedCategory}_mask.tif`),
        }],
        convertToRGB: false,
        normalize: false
      }),
      style: {
        color: [
          'case',
          ['==', ['band', brushIndex + 1], 1],
          ['array', 50, 50, 50, 0.8],
          ['array', 0, 0, 0, 0]
        ]
      },
      properties: {
        purpose: 'mask'
      },
      zIndex: 35
    });

    map.addLayer(maskLayer);
    
    return () => {
      map.removeLayer(maskLayer);
    };
  }, [map, selectedCategory, currentBrush, zIndex]);

  return null;
}