import { useState } from 'react';
import { transform } from 'ol/proj';
import { Map } from 'ol';

interface SearchSystemProps {
  mapInstance: Map | null;
  selectedBands: number[];
  selectedCategory: string;
  CATEGORIES: Array<{ value: string; label: string; file: string }>;
}

export interface NominatimResult {
  place_id: number;
  licence: string;
  osm_type: string;
  osm_id: number;
  lat: string;
  lon: string;
  display_name: string;
  boundingbox: string[];
  class: string;
  type: string;
  importance: number;
}

export const useSearchSystem = ({
  mapInstance,
  selectedBands,
  selectedCategory,
  CATEGORIES
}: SearchSystemProps) => {
  const [searchQuery, setSearchQuery] = useState('');

  const search = async (query: string) => {
    if (!query || !mapInstance) return;
    
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(query)}&format=json&limit=1&countrycodes=gb`
      );
      const data: NominatimResult[] = await response.json();
      
      if (data.length > 0) {
        const result = data[0];
        const coordinates = transform(
          [parseFloat(result.lon), parseFloat(result.lat)], 
          'EPSG:4326', 
          'EPSG:3857'
        );
        
        mapInstance.getView().animate({
          center: coordinates,
          zoom: 17,
          duration: 1000
        });
        
        setSearchQuery(result.display_name);
      }
    } catch (error) {
      console.error('Search error:', error);
    }
  };

  return {
    searchQuery,
    setSearchQuery,
    search
  };
};