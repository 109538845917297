import { Map } from 'ol';
import { Coordinate } from 'ol/coordinate';
import { Polygon } from 'ol/geom';
import { fromArrayBuffer } from 'geotiff';
import { CONFIG } from './config';

export const IS_PRODUCTION = process.env.NODE_ENV === 'production';
export const WORKER_URL = '/worker.js';
export const DEV_URL = 'http://localhost:3000';

export const getAssetUrl = (
  type: 'base' | 'mask' | 'intervention',
  filename: string
) => {
  const url = CONFIG.isProduction 
    ? `${CONFIG.apiUrl}/${type}/${filename}`
    : `${window.location.origin}/bucket/${type}/${filename}`;
    
  return url;
};

export const checkFileExists = async (url: string): Promise<boolean> => {
  try {
    const response = await fetch(url, { method: 'HEAD' });
    return response.ok;
  } catch (error) {
    console.error('Error checking file:', error);
    return false;
  }
};

export const getCategoryFile = (categories: any[], category: string) => {
  return categories.find(cat => cat.value === category)?.file || '';
};

export const getBrushBandIndex = (brushName: string, category: string) => {
  // Implementation depends on your brush configuration
  return 0;
};

export const calculateModificationImpact = (
  coordinate: Coordinate,
  values: number[],
  selectedArea: Polygon
) => {
  return values;
};

export const mapToPixelCoordinates = (map: Map, coordinate: Coordinate) => {
  if (!map) return null;
  return map.getPixelFromCoordinate(coordinate);
};

export const loadGeoTIFF = async (url: string) => {
  try {
    const response = await fetch(url);
    
    if (!response.ok) {
      console.error('[ERROR] Failed to fetch GeoTIFF:', response.status, response.statusText);
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    const arrayBuffer = await response.arrayBuffer();
    
    const tiff = await fromArrayBuffer(arrayBuffer);
    return tiff;
  } catch (error) {
    console.error('[ERROR] Failed to load GeoTIFF:', error);
    throw error;
  }
};

export const polygonToClipCoordinates = (polygon: Polygon): number[][] => {
  const coordinates = polygon.getCoordinates()[0];
  return coordinates.map(coord => [coord[0], coord[1]]);
};