// Colors
export const SELECTED_BLUE = '#2196f3';
export const SELECTED_BLUE_BG = '#e6f3ff';
export const BORDER_COLOR = '#ccc';

export const bandColors = {
  green: '#4CAF50',
  blue: '#2196F3',
  red: '#F44336'
};

export const buttonBackgroundColors = {
  primary: '#2196F3',
  secondary: '#F5F5F5'
};

export const CATEGORIES = [
  { value: 'green_infrastructure', label: 'Green Infrastructure', file: 'green_infrastructure_base.tif' },
  { value: 'energy', label: 'Energy', file: 'energy_base.tif' },
  { value: 'transport', label: 'Transport', file: 'transport_base.tif' }
];

export const API_KEY = 'LB7rXF03VGc5tfGK73uM2zOicPTAwqXC';

// Add any other constants you might need here...