import React from 'react';
import { Page, Document, StyleSheet, View, Text, Image } from '@react-pdf/renderer';
import { format } from 'date-fns';

interface PDFReportLayoutProps {
  baseMapImage: string;
  bandImages: Array<{
    base: string;
    withInterventions: string;
  }>;
  bandDescriptions: string[];
  interventions: Array<{
    name: string;
    area: number;
    cost: number;
    color: string;
    values: number[];
  }>;
  bandTotals: number[];
  totalCost: number;
  selectedArea: number;
  dateGenerated: Date;
  projectName: string;
  location: string;
}

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    padding: 30,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
    borderBottom: '1pt solid #2196F3',
    paddingBottom: 10,
  },
  logo: {
    width: 120,
    height: 40,
  },
  headerText: {
    color: '#2196F3',
    fontSize: 10,
  },
  title: {
    fontSize: 24,
    color: '#2196F3',
    marginBottom: 20,
    fontWeight: 'bold',
  },
  subtitle: {
    fontSize: 16,
    color: '#333',
    marginBottom: 10,
    fontWeight: 'bold',
  },
  section: {
    margin: '10 0',
    padding: 10,
  },
  mapImage: {
    width: '100%',
    height: 300,
    objectFit: 'contain',
    marginBottom: 10,
  },
  bandImage: {
    width: '100%',
    height: 200,
    marginVertical: 5,
  },
  text: {
    fontSize: 12,
    color: '#444',
    marginBottom: 5,
  },
  table: {
    display: 'flex',
    width: '100%',
    marginVertical: 10,
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#eee',
    borderBottomStyle: 'solid',
    alignItems: 'center',
    minHeight: 25,
  },
  tableHeader: {
    backgroundColor: '#f5f5f5',
  },
  tableCell: {
    flex: 1,
    padding: 5,
    fontSize: 10,
  },
  footer: {
    position: 'absolute',
    bottom: 20,
    left: 40,
    right: 40,
    fontSize: 8,
    color: '#666',
    textAlign: 'center',
    borderTop: '1pt solid #eee',
    paddingTop: 10
  },
  chart: {
    width: '100%',
    height: 200,
    marginVertical: 10,
  },
  legend: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 10,
  },
  legendItem: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 20,
    marginBottom: 5,
  },
  legendColor: {
    width: 12,
    height: 12,
    marginRight: 5,
  },
  bandContainer: {
    marginBottom: 15,
    padding: 10,
    borderBottom: '1pt solid #eee',
    minHeight: 300,
    maxHeight: 300,
  },
  bandImagesContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 5,
    height: 200,
  },
  bandImageWrapper: {
    flex: 1,
    marginHorizontal: 5,
    height: '100%',
  },
  bandImageDisplay: {
    width: '100%',
    height: 200,
    objectFit: 'contain',
  },
  imageLabel: {
    fontSize: 10,
    color: '#666',
    textAlign: 'center',
    marginTop: 5,
  },
  interventionRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 5,
    borderBottom: '1pt solid #eee',
  },
  attribution: {
    marginTop: 5,
    fontSize: 7,
    color: '#888'
  }
});

export const PDFReportLayout: React.FC<PDFReportLayoutProps> = ({
  baseMapImage,
  bandImages,
  bandDescriptions,
  interventions,
  bandTotals,
  totalCost,
  selectedArea,
  dateGenerated,
  projectName,
  location,
}) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
        <Text style={styles.headerText}>Urban Biome</Text>
        <Text style={styles.headerText}>
          {format(dateGenerated, 'dd MMM yyyy')}
        </Text>
      </View>
      
      <Text style={styles.title}>Intervention Analysis Report</Text>
      <Text style={styles.text}>Project: {projectName}</Text>
      <Text style={styles.text}>Location: {location}</Text>
      
      <View style={styles.section}>
        <Image src={baseMapImage} style={styles.mapImage} />
      </View>

      {/* Band Analysis */}
      <View style={styles.section}>
        {bandImages.map((images: { base: string; withInterventions: string }, index: number) => (
          <View key={index} wrap={false}>
            <View style={styles.bandContainer}>
              <Text style={styles.text}>{bandDescriptions[index]}</Text>
              <View style={styles.bandImagesContainer}>
                <View style={styles.bandImageWrapper}>
                  <Image src={images.base} style={styles.bandImageDisplay} />
                  <Text style={styles.imageLabel}>Base Data</Text>
                </View>
                <View style={styles.bandImageWrapper}>
                  <Image src={images.withInterventions} style={styles.bandImageDisplay} />
                  <Text style={styles.imageLabel}>With Interventions</Text>
                </View>
              </View>
              <Text style={styles.text}>
                Total: {bandTotals[index]?.toFixed(2) || '0.00'}
              </Text>
            </View>
          </View>
        ))}
      </View>

      {/* Interventions */}
      <View style={styles.section}>
        <Text style={styles.subtitle}>Interventions Summary</Text>
        {interventions.map((intervention: { name: string; area: number; cost: number }, index: number) => (
          <View key={index} style={styles.interventionRow}>
            <Text style={styles.text}>
              {intervention.name}: {intervention.area.toFixed(2)} m²
            </Text>
            <Text style={styles.text}>
              Cost: ${(intervention.area * intervention.cost).toFixed(2)}
            </Text>
          </View>
        ))}
      </View>

      <View style={styles.footer}>
        <Text>Total Area: {selectedArea.toFixed(2)} m²</Text>
        <Text>Total Cost: ${totalCost.toFixed(2)}</Text>
      </View>
      
      {/* Add footer with attribution at bottom of page */}
      <View style={styles.footer}>
        <Text>Generated by Urban Biome</Text>
        <Text style={styles.attribution}>
          Map data © OpenStreetMap contributors
        </Text>
        <Text style={styles.attribution}>
          Available under the Open Database License
        </Text>
      </View>
    </Page>
  </Document>
);